
import { Flex } from "@chakra-ui/react";

export const DrawerStickyFooter = ({ children }: any) => {
  return (
    <Flex
      p="24px"
      w="100%"
      bg="white"
      mt="40px"
      boxShadow="0px -4px 8px rgba(0, 0, 0, 0.05)"
      borderTop="1px solid"
      borderColor="lightgray"
      pt="40px"
      gap="8px"
    >
      {children}
    </Flex>
  );
};