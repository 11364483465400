import { FileUploadInput } from "@/components/shared/form/file-upload.input";
import { qooMediaService } from "@/service/qoo/qoo-media.service";
import { muxService } from "@/service/thirdparty/mux.service";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "@/store";

interface Props {
  qooId: number;
  triggerSave: boolean;
  existingVideo?: { name: string, presignedUrl: string };
}

export const QooUploadVideo: React.FC<Props> = ({
  qooId,
  triggerSave,
  existingVideo,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [video, setVideo] = useState<File>();
  const [videoFilename, setVideoFilename] = useState<string>();

  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isUploaded, setIsUploaded] = useState<boolean>(false);

  const uploadVideos = async () => {
    if (!video || !isUploaded) return;

    setLoading(true);
    dispatch(
      setMessage({
        show: true,
        closable: true,
        message: `Preparing to upload video...`,
      })
    );
    const { s3, mux } = await qooMediaService.generateVideoUploadUrls(
      qooId,
      video.name,
      video.type
    );
    try {
      await Promise.all([
        qooMediaService.uploadVideo(
          s3.presignedUrl,
          video,
          (progress: number) => {}
        ),
        muxService.uploadVideo(mux.url, video, (progress: number) => {
          dispatch(
            setMessage({
              show: true,
              message: `Uploading video... ${progress}%`,
            })
          );
        }),
      ]);

      await qooMediaService
        .completeVideoUploads(qooId, mux.id, s3.id, video.name)
        .then(() => {
          dispatch(
            setMessage({
              show: true,
              closable: true,
              message: "Background uploaded completed successfully.",
            })
          );
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteVideo = () => {
    setVideoFilename(null);
    setVideo(null);
    setIsDeleted(true);
  };

  const deleteExistingVideo = async () => {
    if (!isDeleted || !existingVideo?.presignedUrl) return;

    setLoading(true);
    await qooMediaService.deleteVideo(qooId).finally(() => {
      setLoading(false);
    });
  };

  const handleDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;

    const file = acceptedFiles[0];
    setVideoFilename(file.name);
    setVideo(file);

    setIsDeleted(true);
    setIsUploaded(true);
  }, []);

  useEffect(() => {
    if (!triggerSave) return;

    deleteExistingVideo();
    uploadVideos();
  }, [triggerSave]);

  useEffect(() => {
    setVideoFilename(existingVideo?.name);
  }, [existingVideo]);

  return (
    <FileUploadInput
      onDrop={handleDrop}
      accept={{
        "video/*": [
          ".mp4",
          ".MP4",
          ".mov",
          ".MOV",
          ".wmv",
          ".WMV",
          ".flv",
          ".FLV",
          ".avi",
          ".AVI",
        ],
      }}
      maxFiles={1}
      filenames={videoFilename ? [videoFilename] : undefined}
      isInvalid={false}
      invalidInputMessage="Invalid file type. Please upload a video file."
      additionalText="MP4, MOV, WMV, FLV, AVI files are accepted."
      type="video"
      title=""
      onDelete={handleDeleteVideo}
    />
  );
};
