import { FC, useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Tag,
  TagLabel,
  VStack,
  useClipboard,
  useTheme,
  useToast,
} from "@chakra-ui/react";
import {
  BackIcon,
  Icons,
  NextIcon,
  QoosIcon,
  ShareIcon,
  PlayIcon,
} from "../icons/CustomIcons";
import { Button } from "../third-party/shadcn/button";
import { qooMediaService } from "@/service/qoo/qoo-media.service";
import { DrawerStickyFooter } from "./parts/DrawerStickyFooter";
import { qooService } from "@/service/qoo/qoo.service";
import { VideoModal } from "../shared/modal/video/VideoModal";
import { HtmlVideoPlayer } from "../shared/video/HtmlVideoPlayer";
import { VideoUploadInProgress } from "../shared/video/VideoUploadInProgress";
import { GenerateScriptModal } from "../shared/modal/video/GenerateScriptModal";
import { HtmlVideoModal } from "../shared/modal/video/HtmlVideoModal";
import { VideoThumbnail } from "../shared/video/VideoThumbnail";

interface QoosStreamPreviewDrawerProps {
  isOpen: boolean;
  qoos?: any[];
  onClose: () => void;
  onEdit?: (qooId: number) => void;
  onDelete?: (qooId: number) => void;
  displayId?: boolean;
  displayTags?: boolean;
  index?: number;
  onIndexChange?: (index: number) => void;
}

export enum DislikeOption {
  Irrelevant = "Irrelevant",
  HardToUnderstand = "Hard to understand",
  DifficultToFollow = "Difficult to follow",
  Other = "Other (comment)",
}

export const QooPreviewDrawer: FC<QoosStreamPreviewDrawerProps> = ({
  isOpen,
  onClose,
  onDelete,
  qoos,
  onEdit,
  displayId,
  displayTags,
  index,
  onIndexChange,
}) => {
  const [load, setLoad] = useState<boolean>(true);
  const [qoo, setQoo] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(index ?? 0);
  const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);
  const [hasLiked, setHasLiked] = useState<boolean>(false);
  const [hasDisliked, setHasDisliked] = useState<boolean>(false);
  const [shareQooModalOpen, setShareQooModalOpen] = useState<boolean>(false);
  const [isGenerateScriptOpen, setIsGenerateScriptOpen] =
    useState<boolean>(false);
  const [previousDislike, setPreviousDislike] = useState<DislikeOption | null>(
    null
  );

  // For asset
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  const { colors } = useTheme();
  const toast = useToast();
  const { onCopy } = useClipboard(
    `${window.location.origin}?qooId=${displayId && qoo?.id.toString()}`
  );

  // id would not exist yet if qoo has is not created
  const isPreCreatePreview = qoos && qoos.length > 0 && !qoos?.[0]?.id;

  const onDownload = async (docId: number, filename: string, url?: string) => {
    let downloadUrl;
    if (url) {
      downloadUrl = url;
    } else {
      const resp = await qooMediaService.getDocumentDownloadUrl(docId);
      downloadUrl = resp.downloadUrl;
    }
    window.open(downloadUrl, "_blank");
  };

  const handleNextQoo = () => {
    setCurrentIndex((prev) => prev + 1);
    onIndexChange?.(currentIndex + 1);

    setHasDisliked(false);
    setHasLiked(false);
    setPreviousDislike(null);
  };

  const handleBackQoo = () => {
    setCurrentIndex((prev) => prev - 1);
    onIndexChange?.(currentIndex - 1);

    setPreviousDislike(null);
  };

  const handleVideoClose = () => {
    setIsVideoOpen(false);
  };

  const handleClose = () => {
    setQoo(null);
    onClose();
    setCurrentIndex(0);
    setHasLiked(false);
    setHasDisliked(false);
    setPreviousDislike(null);
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this qoo?"
    );
    if (!confirmDelete) return;
    setLoad(true);
    await qooService.delete(qoo?.id).finally(() => setLoad(false));
    toast({ title: "Qoo deleted", status: "success" });
    onClose();
    onDelete?.(qoo?.id);
  };

  const handlePlayVideo = (url: string) => {
    setVideoUrl(url);
  };

  const fetchQoo = useCallback(async () => {
    setLoad(true);
    if (!qoos?.length) return;

    await qooService
      .get(qoos[currentIndex].id)
      .then((resp) => {
        setQoo(resp);
      })
      .finally(() => {
        setLoad(false);
      });
  }, [currentIndex, qoos]);

  useEffect(() => {
    const fetchData = async () => {
      setLoad(true);
      await fetchQoo();
      setLoad(false);
    };

    const setData = async () => {
      setLoad(true);
      const qooData = qoos[0];
      setQoo(qooData);
      setLoad(false);
    };

    if (isOpen) {
      if (isPreCreatePreview) {
        setData();
      } else {
        fetchData();
      }
    }
  }, [isOpen, fetchQoo, isPreCreatePreview]);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={handleClose} size="md">
      <DrawerOverlay />
      <DrawerContent pr="8px">
        <div className="flex flex-row items-center justify-between px-4 pt-1">
          <div className="flex flex-row gap-1 items-center">
            <div className="text-[20px] text-gray-500 font-semibold">
              # <span>{qoo?.id}</span>
            </div>
          </div>

          <div className="cursor-pointer" onClick={handleClose}>
            <Icons.Error />
          </div>
        </div>
        <DrawerHeader display="flex" alignItems="center">
          <Flex alignItems="center" width="100%">
            <Box overflow="hidden">
              <div>{qoo?.title ? qoo.title : "Preview Qoo"}</div>
            </Box>
          </Flex>
        </DrawerHeader>

        <DrawerBody className="" overflowY={"auto"}>
          {load ? (
            <Flex justify="center" align="center" height="100%">
              <Spinner />
            </Flex>
          ) : (
            <VStack align="stretch" spacing={6}>
              <Flex flexDir="column" gap={2}>
                {qoo?.video?.presignedUrl ? (
                  <HtmlVideoPlayer src={qoo?.video?.presignedUrl} />
                ) : (
                  <>
                    {qoo?.heygenVideo?.status === "STARTED" && (
                      <VideoUploadInProgress />
                    )}
                  </>
                )}

                <Box
                  textAlign="start"
                  fontSize="xl"
                  fontWeight="bold"
                  mt="12px"
                >
                  Action
                </Box>

                {qoo?.actions && qoo.actions.length > 0 ? (
                  <Box
                    w="100%"
                    p="16px"
                    bgColor="#F0F0FF"
                    borderRadius="12px"
                    border="1px solid #565ADD"
                  >
                    <div>{qoo.actions[0]}</div>
                  </Box>
                ) : (
                  <div className="text-gray-400">No action available</div>
                )}
              </Flex>

              <VStack gap={5} align="stretch">
                <div className="text-xl font-bold mb-2">
                  {" "}
                  {"Why is this important:"}
                </div>
                {qoo?.bulletpoint && qoo?.bulletpoint.length > 0 ? (
                  qoo.bulletpoint.map((point: string, index: number) => (
                    <Flex key={index} gap={3}>
                      <Box>
                        <Icon as={QoosIcon} boxSize={6} />
                      </Box>
                      <Box>{point}</Box>
                    </Flex>
                  ))
                ) : (
                  <div className="text-gray-500">No points available</div>
                )}
              </VStack>

              <VStack align="stretch" gap={3}>
                <div className="text-xl font-bold mb-[4px]">
                  Watch Complete Session
                </div>
                {qoo?.documentFiles.map((doc: any, index: number) => (
                  <Flex flexDir="row" alignItems="center" key={index} gap={3}>
                    <Box>{doc.originalName ?? "Untitled document"}</Box>
                    <div
                      onClick={() =>
                        onDownload(doc.id, doc.originalName, doc.url)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Icons.Download />
                    </div>
                  </Flex>
                ))}
                {!!qoo?.originalVideo && (
                  <Flex flexDir="row" alignItems="center" gap={3}>
                    <VideoThumbnail
                      src={qoo.originalVideo.presignedUrl}
                      title={qoo.originalVideo.name ?? "Original Video"}
                      width="130px"
                      height="80px"
                      onPlayVideo={handlePlayVideo}
                    />
                  </Flex>
                )}
              </VStack>
              <>
                {displayTags && (
                  <Box>
                    <div className="font-bold mb-2">Tags</div>
                    {qoo?.tags && qoo.tags.length > 0 ? (
                      qoo.tags.map((tag: string, index: number) => (
                        <Tag
                          mr={2}
                          mb={2.5}
                          key={index}
                          size="lg"
                          colorScheme="purple"
                        >
                          <TagLabel>{tag}</TagLabel>
                        </Tag>
                      ))
                    ) : (
                      <div className="text-gray-500">No tags found</div>
                    )}
                  </Box>
                )}
              </>
            </VStack>
          )}
        </DrawerBody>
        <Flex flexDir="column">
          <DrawerStickyFooter>
            <Flex
              width="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex gap="10px">
                <Button size="sm" onClick={() => onEdit?.(qoo?.id)}>
                  <Icons.Edit fill="white" width="16" height="16" />
                </Button>

                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setIsGenerateScriptOpen(true)}
                >
                  <Icons.DocumentOutline width="20" height="20" />
                </Button>

                <Button variant="destructive" size="sm" onClick={handleDelete}>
                  <Icons.Trash stroke="white" width="16" height="16" />
                </Button>
              </Flex>
              <Flex gap="10px">
                <Button
                  variant="outline"
                  disabled={currentIndex === 0}
                  onClick={handleBackQoo}
                >
                  <BackIcon
                    boxSize={5}
                    color={currentIndex === 0 ? "#9CA3AF" : "primary"}
                  />
                </Button>
                <Button
                  disabled={currentIndex + 1 >= qoos?.length}
                  onClick={handleNextQoo}
                >
                  <NextIcon
                    boxSize={5}
                    color={
                      currentIndex + 1 >= qoos?.length ? "#9CA3AF" : "white"
                    }
                  />
                </Button>
              </Flex>
            </Flex>
          </DrawerStickyFooter>
        </Flex>
        {isVideoOpen && qoo && (
          <VideoModal
            isOpen={isVideoOpen}
            onClose={handleVideoClose}
            playbackId={qoo?.videoPlaybackId}
          />
        )}

        <HtmlVideoModal
          isOpen={!!videoUrl}
          onClose={() => setVideoUrl(null)}
          src={videoUrl || ""}
        />

        <GenerateScriptModal
          isOpen={isGenerateScriptOpen}
          onGenerateVideo={() => {
            setQoo({
              ...qoo,
              video: {
                presignedUrl: null,
              },
              heygenVideo: {
                status: "STARTED",
              },
            });
          }}
          onClose={() => setIsGenerateScriptOpen(false)}
          qooId={qoo?.id}
          heygenThirdpartyId={qoo?.heygenVideo?.id}
        />
      </DrawerContent>
    </Drawer>
  );
};
