import { Box } from "@chakra-ui/react";
import MuxPlayer from "@mux/mux-player-react";

export const MuxVideoPlayer = ({ playbackId, autoplay = true, ...props }: any) => {
  return (
    <Box
      borderRadius="20px"
      bg="transparent"
      overflow="hidden"
      h="504px"
      w="100%"
      {...props}
    >
      <MuxPlayer
        autoPlay={autoplay}
        style={{
          height: "100%",
          "--seek-backward-button": "none",
          "--seek-forward-button": "none",
        } as any}
        streamType="on-demand"
        forwardSeekOffset={null}
        backwardSeekOffset={null}
        playbackId={playbackId}
        metadata={{
          video_id: "video-id-54321",
          video_title: "Test video title",
          viewer_user_id: "user-id-007",
        }}
      />
    </Box>
  );
};