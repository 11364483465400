import { Icons } from "@/components/icons";
import React, { useState, useEffect } from "react";

interface VideoUploadInProgressProps {
  className?: string;
}

export const VideoUploadInProgress: React.FC<VideoUploadInProgressProps> = ({
  className,
}) => {
  return (
    <div
      className={`w-full h-[253px] rounded-lg border border-gray-300 flex flex-col gap-4 items-center justify-center ${className}`}
    >
      <div className="flex flex-row gap-1">
        <Icons.Wand width={20} height={20} />
        <div className="text-center text-sm">
          AI Video Generation in progress...
        </div>
      </div>
      <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-primary"></div>
      <div className="text-center text-sm">Check back in a few minutes</div>
    </div>
  );
};
