import { useEffect, useRef, useState } from "react";
import { qooMediaService } from "@/service/qoo/qoo-media.service";

export const HeygenTemplateDropdown = ({ onSelect }: { onSelect: (template: { template_id: number, name: string, thumbnail_image_url: string }) => void }) => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const fetchTemplates = async () => {
      const resp = await qooMediaService.getHeygenTemplates();
      const sortedTemplates = resp.sort((a, b) => a.name.localeCompare(b.name));
      setTemplates(sortedTemplates);
      if (resp.length > 0) {
        setSelectedTemplate(resp[0]); // Set the first template as default
      }
    };
    fetchTemplates();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (template) => {
    setSelectedTemplate(template);
    setIsOpen(false);
  };

  useEffect(() => {
    if (selectedTemplate) {
      onSelect(selectedTemplate);
    }
  }, [selectedTemplate]);

  return (
    <div ref={dropdownRef} className="dropdown relative my-[4px]">
      <div className="text-sm font-semibold py-[2px]">Select Template</div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="dropdown-button border border-gray-300 rounded-md p-2 w-full text-sm text-left flex items-center"
      >
        {selectedTemplate ? (
          <>
            <img 
              src={selectedTemplate.thumbnail_image_url} 
              alt={selectedTemplate.name} 
              className="w-[40px] h-auto mr-2"
            />
            {selectedTemplate.name}
          </>
        ) : "Select a template"}
      </button>
      {isOpen && (
        <ul
          className="dropdown-menu absolute bg-white border border-gray-300 rounded-md mb-1 w-full z-10 shadow-md"
          style={{ bottom: "60%", left: 0 }}
        >
          {templates.map((template) => (
            <li
              key={template.template_id}
              onClick={() => handleSelect(template)}
              className="dropdown-item p-2 hover:bg-gray-100 cursor-pointer flex items-center"
            >
              <img 
                src={template.thumbnail_image_url} 
                alt={template.name} 
                className="w-[80px] h-auto mr-2"
              />
              {template.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
