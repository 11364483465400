import { FileUploadInput } from "@/components/shared/form/file-upload.input";
import { toast } from "@/components/third-party/shadcn/use-toast";
import { qooMediaService } from "@/service/qoo/qoo-media.service";
import { qooService } from "@/service/qoo/qoo.service";
import { useCallback, useEffect, useState } from "react";

interface Props {
  qooId: number;
  triggerSave: boolean;
  existingFiles: { id: number; originalName: string }[];
  onDeleteDocument?: (docId?: number) => void;
}

export const QooUploadDoc: React.FC<Props> = ({
  qooId,
  triggerSave,
  existingFiles,
  onDeleteDocument,
}) => {
  const [formState, setFormState] = useState({} as any);
  const [loading, setLoading] = useState(false);

  // Docs
  const [uploadedDocuments, setUploadedDocuments] = useState<File[]>([]);
  const [deletedDocumentIds, setDeletedDocumentIds] = useState<number[]>([]);

  const [documentFilename, setDocumentFilename] = useState<string[]>([]);
  const [existingDocuments, setExistingDocuments] = useState<
    { id: number; name: string }[]
  >([]);
  const [uploadDocumentsProgress, setUploadDocumentsProgress] = useState(0);

  const uploadDocuments = async () => {
    if (!uploadedDocuments.length) return;

    setLoading(true);
    await qooMediaService
      .uploadDocuments(qooId, uploadedDocuments)
      .then(() => {
        toast({
          title: "Document uploaded",
          description: "Document has been uploaded successfully",
        });
      })
      .catch(() => {
        toast({
          title: "Document upload failed",
          description: "Document upload failed",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteDocument = async (
    filename: string,
    isExisting: boolean,
    docId?: string
  ) => {
    setDocumentFilename((prev) => prev.filter((name) => name !== filename));

    if (!isExisting) return;

    setExistingDocuments((prev) => prev.filter((doc) => doc.name !== filename));
    toast({ title: "Document deleted", description: "success" });

    if (!docId) return;
    setDeletedDocumentIds((prev) => [...prev, parseInt(docId)]);
    onDeleteDocument?.(parseInt(docId));
  };

  const deleteDocuments = async () => {
    if (!deletedDocumentIds.length) return;

    const promises = deletedDocumentIds.map((docId) =>
      qooMediaService.deleteDocument(docId)
    );

    setLoading(true);
    await Promise.all(promises)
      .then(() => {
        toast({
          title: "Document deleted",
          description: "Document has been deleted successfully",
        });
      })
      .catch(() => {
        toast({
          title: "Document deletion failed",
          description: "Document deletion failed",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;

    // check acceptedFiles names to ensure no duplicates
    const hasDuplicates =
      acceptedFiles.some(
        (file) => documentFilename.indexOf(file.name) !== -1
      ) ||
      acceptedFiles.some((file) =>
        existingDocuments.some((doc) => doc.name === file.name)
      );

    if (hasDuplicates) {
      alert("Duplicate files are not allowed.");
      return;
    }

    setUploadedDocuments(acceptedFiles);
    setDocumentFilename(acceptedFiles.map((file) => file.name));
  }, []);

  useEffect(() => {
    if (!triggerSave) return;

    uploadDocuments();
    deleteDocuments();
  }, [triggerSave]);

  useEffect(() => {
    if (!existingFiles?.length) return;

    setExistingDocuments(
      existingFiles.map((file) => ({ id: file.id, name: file.originalName }))
    );
  }, [existingFiles]);

  return (
    <FileUploadInput
      onDrop={handleDrop}
      accept={{
        "application/pdf": [".pdf"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      }}
      maxFiles={5}
      filenames={documentFilename}
      existingFiles={existingDocuments}
      isInvalid={false}
      invalidInputMessage="Invalid file type. Please upload a PDF file."
      additionalText="PDF, XLSX, DOC, DOCX files are accepted."
      type="document"
      title="Upload Documents"
      uploadProgress={uploadDocumentsProgress}
      onDelete={handleDeleteDocument}
    />
  );
};
