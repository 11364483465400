import React, { useState, useEffect } from 'react';

interface TimeElapsedTimerProps {
  startDate: Date | string;
}

const TimeElapsedTimer: React.FC<TimeElapsedTimerProps> = ({ startDate }) => {
  const [elapsedTime, setElapsedTime] = useState<string>('00:00:00');

  useEffect(() => {
    const updateElapsedTime = () => {
      const now = new Date();
      const diff = now.getTime() - new Date(startDate).getTime();

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      const formattedTime = [
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0'),
      ].join(':');

      setElapsedTime(formattedTime);
    };

    updateElapsedTime();
    const intervalId = setInterval(updateElapsedTime, 1000);

    return () => clearInterval(intervalId);
  }, [startDate]);

  return <div className='text-[14px]'>{elapsedTime}</div>;
};

export default TimeElapsedTimer;