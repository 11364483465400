import * as UpChunk from '@mux/upchunk';

export class MuxService {
  
  async uploadVideo(url: string, file: File, onProgress: (progress: number) => void): Promise<void> {
    return new Promise((resolve, reject) => {
      const upload = UpChunk.createUpload({
        endpoint: url,
        file: file,
        chunkSize: 5120,
      });

      upload.on('progress', progress => {
        onProgress(Math.round(progress.detail));
      });

      upload.on('success', () => {
        resolve();
      });

      upload.on('error', (error) => {
        reject(error);
      });
    });
  }

}

export const muxService = new MuxService();