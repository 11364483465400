import React, { useState, useEffect } from 'react';

interface HtmlVideoPlayerProps {
  src: string;
  className?: string;
}

export const HtmlVideoPlayer: React.FC<HtmlVideoPlayerProps> = ({ src, className }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 400);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`w-full rounded-lg overflow-hidden flex items-center justify-center ${className}`}>
      {isLoading ? (
        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full border-t-transparent border-primary"></div>
      ) : (
        <video key={src} controls className="w-full h-full object-cover">
          <source key={src} src={src} />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};