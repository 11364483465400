import React, { useCallback, useEffect, useState } from "react";
import { MuxVideoPlayer } from "../../video/MuxVideoPlayer";
import { Flex, Modal, ModalContent, ModalOverlay, Spinner, Text } from "@chakra-ui/react";


export const VideoModal: React.FC<any> = ({ isOpen, onClose, playbackId }) => {
  return (
     <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="4xl"
      >
        <ModalOverlay />
          <ModalContent borderRadius="10px">
            {!playbackId ? (
              <Flex height="35vh" justifyContent="center" alignItems="center" >  
                <Spinner justifySelf="center" />
              </Flex>
            ) : (
              <MuxVideoPlayer key={playbackId} playbackId={playbackId} />
            )}
        </ModalContent>
      </Modal>
  );
};