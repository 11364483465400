import { Icons } from "@/components/icons";
import { formatTimeDuration } from "@/lib/utils/date";
import React, { useState, useEffect } from "react";

interface VideoThumbnailProps {
  src: string;
  title: string;
  className?: string;
  width?: string;
  height?: string;
  onPlayVideo?: (src: string) => void;
}

export const VideoThumbnail: React.FC<VideoThumbnailProps> = ({
  src,
  title,
  className,
  width,
  height,
  onPlayVideo,
}) => {
  const [duration, setDuration] = useState<number | null>(null);

  const handleLoadedMetadata = (
    event: React.SyntheticEvent<HTMLVideoElement>
  ) => {
    setDuration(event.currentTarget.duration);
  };

  return (
    <div className="flex flex-row gap-4 items-center">
      <div
        className={`w-full rounded-lg overflow-hidden flex items-center justify-center ${className} border border-gray-200`}
        style={{ width, height, position: "relative" }}
      >
        <video
          key={src}
          className="w-full h-full object-cover"
          onLoadedMetadata={handleLoadedMetadata}
        >
          <source key={src} src={src} />
          Your browser does not support the video tag.
        </video>

        {onPlayVideo && (
          <div
            onClick={() => onPlayVideo(src)}
            color={"#565ADD"}
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Icons.PlayAlt width="40" height="40" fill="white" />
          </div>
        )}
      </div>

      <div className="flex flex-col">
        <div className="font-bold">{title}</div>
        {duration !== null && (
          <div className="">{formatTimeDuration(duration)}</div>
        )}
      </div>
    </div>
  );
};
