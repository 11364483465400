import { BaseService } from "../base.service";

class QooService extends BaseService {
  async update(qoo: any) {
    const resp = await this.client.put(`/content/qoo/${qoo.id}`, { ...qoo });
    return resp?.data;
  }

  async get(qooId: number) {
    const resp = await this.client.get(`/content/qoo/${qooId}`);
    return resp?.data;
  }

  async delete(qooId: number) {
    const resp = await this.client.delete(`/content/qoo/${qooId}`);
    return resp?.data;
  }

  async getContentQoos(filter?: { transcriptChunkId?: number }) {
    const resp = await this.client.post(`/content/qoo/list`, { filter });
    return resp?.data;
  }

  async updateQoosStreamLinks(streamId: number, qoos: any[]) {
    const resp = await this.client.put(`/content/qoo/stream-links`, { streamId, qoos });
    return resp?.data;
  }
}

export const qooService = new QooService();
