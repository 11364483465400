import React from 'react';

interface Props {
  text?: string;
}

const Divider: React.FC<Props> = ({ text }) => {
  return (
    <div className="flex items-center justify-center gap-4">
      <div className="border-b-2 border-gray w-1/2"></div>
      {text && <div className="text-[14px] font-semibold">{text}</div>}
      <div className="border-b-2 border-gray w-1/2"></div>
    </div>
  );
};

export default Divider;