import { Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { HtmlVideoPlayer } from "../../video/HtmlVideoPlayer";

export const HtmlVideoModal = ({
  isOpen,
  onClose,
  src,
}: {
  isOpen: boolean;
  onClose: any;
  src: string;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent
        padding={0}
        overflow="hidden"
        borderRadius="lg"
        boxShadow="none"
        border="none"
        background="transparent"
      >
        <HtmlVideoPlayer src={src} className="h-[312px]" />
      </ModalContent>
    </Modal>
  );
};
