import { BaseService } from "../base.service";
import Config from "@/config";

class TranscriptService extends BaseService {
  async save(s3MediaId: number, transcript: string) {
    const resp = await this.client.post(`/content/transcript`, { s3MediaId, transcript });
    return resp?.data;
  }

  async get(s3MediaId: number): Promise<{ content: string }> {
    const resp = await this.client.get(`/content/transcript?s3MediaId=${s3MediaId}`);
    return resp?.data;
  }

  async *transcriptGenerator(s3MediaId: number, save: boolean = false) {
    const accessToken = this.getAccessToken();
  
    try {
      const resp = await fetch(`${Config.apiUrl}/content/transcript/generate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ s3MediaId, save }),
      });
  
      if (!resp.ok) {
        throw new Error(`Failed to convert media to transcript: ${resp.status} ${resp.statusText}`);
      }
  
      if (!resp.body) {
        throw new Error("Response body is null");
      }
  
      const reader = resp.body.getReader();
      const decoder = new TextDecoder("utf-8");
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        const chunk = decoder.decode(value, { stream: true });
        yield chunk;
      }
    } catch (error) {
      console.error("Error in transcriptGenerator:", error);
      throw error;
    }
  }
}

export const transcriptService = new TranscriptService();
