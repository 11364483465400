import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { BaseService } from "../base.service";

class QooMediaService extends BaseService {
  uploadClient: AxiosInstance;

  constructor() {
    super();
    this.uploadClient = axios.create({
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
    });
  }

  async getHeygenScript(heygenThirdpartyId: number) {
    const resp = await this.client.get(
      `/content/qoo/video/heygen/script?heygenThirdpartyId=${heygenThirdpartyId}`
    );
    return resp.data;
  }

  async getHeygenTemplates() {
    const resp = await this.client.get(
      "/content/qoo/video/heygen/template/list"
    );
    return resp.data;
  }

  async generateHeygenVideo(qooId: number) {
    const resp = await this.client.post("/content/qoo/video/generate", {
      qooId,
    });
    return resp.data;
  }

  async regenerateHeygenVideo(
    qooId: number,
    templateId: number,
    script: string
  ) {
    const resp = await this.client.post("/content/qoo/video/regenerate", {
      qooId,
      templateId,
      script,
    });
    return resp.data;
  }

  /** Call this if you want to test the script generation without generating a video.
   *  This will not save the script to the database.
   */
  async generateVideoScript(qooId: number, prompt?: string) {
    const resp = await this.client.post("/content/qoo/video/generate/script", {
      qooId,
      prompt,
    });
    return resp.data;
  }

  async uploadDocuments(
    qooId: number,
    files: any,
    onProgress?: (progress: number) => void
  ) {
    const formData = new FormData();
    files.forEach((file: File) => {
      formData.append("files", file);
    });
    formData.append("qooId", qooId.toString());

    const config: AxiosRequestConfig = {
      onUploadProgress: (progressEvent: any) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onProgress && onProgress(percentCompleted);
      },
    };

    const resp = await this.client.post(
      "/content/qoo/document",
      formData,
      config
    );
    return resp.data;
  }

  async generateVideoUploadUrls(
    qooId: number,
    fileName: string,
    mimeType: string
  ) {
    const resp = await this.client.post("/content/qoo/video/create", {
      qooId,
      fileName,
      mimeType,
    });

    return resp?.data;
  }

  async deleteDocument(documentId: number) {
    await this.client.delete(`/content/qoo/document/${documentId}`);
    return documentId;
  }

  async deleteVideo(qooId: number) {
    const resp = await this.client.delete(`/content/qoo/video?qooId=${qooId}`);
    return resp.data;
  }

  /**
   *
   * @param videoId is dbo.thirdparty_heygen.id
   * @param externalVideoId is the external video id from Heygen
   * @returns
   */
  async deleteHeygenVideo(
    qooId: number,
    videoId: number,
    externalVideoId: string
  ) {
    const resp = await this.client.delete(
      `/content/qoo/video/heygen?qooId=${qooId}&videoId=${videoId}&externalVideoId=${externalVideoId}`
    );
    return resp.data;
  }

  async getDocumentDownloadUrl(documentId: number) {
    const resp = await this.client.get(
      `/content/qoo/document?docId=${documentId}`
    );
    return resp.data;
  }

  async completeVideoUploads(
    qooId: number,
    muxAssetId: string,
    s3MediaId: string,
    fileName: string
  ) {
    const resp = await this.client.post("/content/qoo/video/upload/complete", {
      qooId,
      muxAssetId,
      s3MediaId,
      fileName,
    });
    return resp.data;
  }

  async uploadVideo(
    url: string,
    file: File,
    onProgress?: (progress: number) => void,
    onError?: (error: any) => void,
    onFinally?: (data: any) => void
  ) {
    const config: AxiosRequestConfig = {
      ...(onProgress && {
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          onProgress(percentCompleted);
        },
      }),
      headers: {
        "Content-Type": file.type,
      },
    };

    try {
      const resp = await this.uploadClient.put(url, file, config);
      onFinally && onFinally(resp?.data);
      return resp?.data;
    } catch (error) {
      onError && onError(error);
      throw error;
    }
  }
}

export const qooMediaService = new QooMediaService();
