import { TranscriptChunk } from "@/models/transcript-chunk.model";
import { BaseService } from "../base.service";
import { QooModel } from "@/models/qoo.model";

class ChunkService extends BaseService {
  async generateChunks(s3MediaId: number, transcriptText: string) {
    const resp = await this.client.post("/content/chunk/generate/list", {
      transcriptText,
      s3MediaId,
    });

    return resp?.data;
  }

  /**
   * Creates a new Chunk and Qoo and appends to a s3 Media (mediaId) and qoo stream.
   */
  async createChunkWithQoo(s3MediaId: number, streamId: number, qooIndex: number, qoo: QooModel) {
    const resp = await this.client.post("/content/chunk/qoo/create", {
      ...qoo,
      streamId,
      mediaId: s3MediaId,      
      index: qooIndex
    });
    return resp?.data;
  }

  async convertTranscriptChunkToQoo(chunkId: number) {
    const resp = await this.client.post("/content/chunk/qoo/generate", {
      chunkId,
    });

    return resp?.data;
  }

  async updateChunk(chunk: TranscriptChunk) {
    const resp = await this.client.put("/content/chunk", chunk);

    return resp?.data;
  }
}

export const chunkService = new ChunkService();
