import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useTheme,
} from "@chakra-ui/react";
import { BackIcon, Icons, NextIcon, TrashIcon } from "../icons/CustomIcons";
import { qooService } from "@/service/qoo/qoo.service";
import { DrawerStickyFooter } from "./parts/DrawerStickyFooter";
import { Input } from "../base/Input";
import { Textarea } from "../base/Textarea";
import { toast } from "../third-party/shadcn/use-toast";
import { Separator } from "../third-party/shadcn/separator";
import { QooUploadDoc } from "./parts/QooUploadDoc";
import { QooUploadVideo } from "./parts/QooUploadVideo";
import { QooModel } from "@/models/qoo.model";
import { qooMediaService } from "@/service/qoo/qoo-media.service";
import { Button } from "../base/Button";
import { ProgressStatusEnum } from "@/models/enums/progress-status.enum";
import TimeElapsedTimer from "../shared/time-elapsed-timer";
import { HtmlVideoModal } from "../shared/modal/video/HtmlVideoModal";
import { AlertDialog } from "../shared/modal/alert/AlertDialog";
import Divider from "../shared/divider";

interface Props {
  id: number;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (qoo) => void;
  onVideoGenerate?: (qoo) => void;
}

export const QooEditDrawer: FC<Props> = ({
  id,
  isOpen,
  onClose,
  onSubmit,
  onVideoGenerate,
}) => {
  const [load, setLoad] = useState<boolean>(true);
  const [qoo, setQoo] = useState<QooModel>({} as QooModel);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [generateTriggered, setGenerateTriggered] = useState<boolean>(false);

  const handleAddItem = (property: "bulletpoint" | "actions" | "tags") => {
    const newQoo = { ...qoo };
    newQoo[property] = [...(newQoo[property] ?? []), ""];
    setQoo(newQoo);
  };

  const handleDeleteItem = (
    property: "bulletpoint" | "actions" | "tags",
    index: number
  ) => {
    const newQoo = { ...qoo };
    newQoo[property].splice(index, 1);
    setQoo(newQoo);
  };

  const handleSave = async () => {
    const filteredActions = qoo.actions?.filter(
      (action: string) => action.trim() !== ""
    );
    const filteredBulletpoints = qoo.bulletpoint?.filter(
      (point: string) => point.trim() !== ""
    );
    const filteredTags = qoo.tags?.filter(
      (point: string) => point.trim() !== ""
    );

    const payload = {
      ...qoo,
      actions: filteredActions,
      bulletpoint: filteredBulletpoints,
      tags: filteredTags,
    };

    setIsSaving(true);
    await qooService
      .update(payload)
      .then(() => {
        toast({
          title: "Qoo updated",
          description: "Qoo has been updated successfully",
        });

        onSubmit(payload);
        handleClose();
      })
      .catch(() => {
        toast({
          title: "Error",
          description: "An error occurred while updating qoo",
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleDeleteDocument = async (documentId: number) => {
    if (!documentId) return;
    const newQoo = { ...qoo };
    newQoo.documentFiles = newQoo.documentFiles.filter(
      (doc: any) => doc.id !== documentId
    );
    setQoo(newQoo);
  };

  const handleClose = () => {
    setGenerateTriggered(false);
    onClose();
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    type: "actions" | "bulletpoint" | "tags"
  ) => {
    const newQoo = { ...qoo };
    newQoo[type][index] = e.target.value;
    setQoo(newQoo);
  };

  const handleTextChange = (e: any) => {
    setQoo({ ...qoo, [e.target.name]: e.target.value });
  };

  const fetchQoo = useCallback(async () => {
    setLoad(true);
    await qooService
      .get(id)
      .then((resp) => {
        setQoo(resp);
      })
      .finally(() => {
        setLoad(false);
      });
  }, [id]);

  useEffect(() => {
    if (isOpen && id) {
      fetchQoo();
    }
  }, [isOpen, id, fetchQoo]);

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={handleClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <div className="flex flex-row items-center justify-between px-4 pt-1">
          <div className="flex flex-row gap-1 items-center">
            <div className="text-[20px] text-gray-500 font-semibold">
              # <span>{qoo?.id}</span>
            </div>
          </div>

          <div className="cursor-pointer" onClick={handleClose}>
            <Icons.Error />
          </div>
        </div>

        <DrawerHeader display="flex" alignItems="center">
          <Flex alignItems="center" width="100%">
            <Box overflow="hidden">
              <div>Edit Qoo</div>
            </Box>
          </Flex>
        </DrawerHeader>

        <DrawerBody overflowY={"auto"}>
          <div className="flex flex-col h-[100%] w-full">
            <div className="flex flex-col align-center gap-4 text-[18px] mt-4">
              <Input
                name="title"
                label="Title"
                value={qoo.title}
                onChange={handleTextChange}
              />

              <div className="flex flex-col gap-4 border-[1px] border-gray p-4 rounded-md">
                <div className="flex justify-between items-center">
                  <div className="text-[14px] font-semibold">
                    Actions to Take
                  </div>
                  <div
                    className="text-[14px] font-semibold cursor-pointer"
                    onClick={() => handleAddItem("actions")}
                  >
                    Add Action +
                  </div>
                </div>

                {qoo?.actions?.map((action: any, index: number) => (
                  <div key={index} className="flex flex-row gap-2 items-center">
                    <Input
                      name={`action-${index}`}
                      value={action}
                      onChange={(e: any) =>
                        handleInputChange(e, index, "actions")
                      }
                    />
                    <span
                      className="cursor-pointer"
                      onClick={() => handleDeleteItem("actions", index)}
                    >
                      <TrashIcon />
                    </span>
                  </div>
                ))}
              </div>
              <div className="flex flex-col gap-4 border-[1px] border-gray p-4 rounded-md">
                <div className="flex justify-between items-center">
                  <div className="text-[14px] font-semibold">
                    Supporting Information
                  </div>
                  <div
                    className="text-[14px] font-semibold cursor-pointer"
                    onClick={() => handleAddItem("bulletpoint")}
                  >
                    Add Item +
                  </div>
                </div>

                {qoo?.bulletpoint?.map((bulletpoint: any, index: number) => (
                  <div key={index} className="flex flex-row gap-2 items-center">
                    <Input
                      name={`bulletpoint-${index}`}
                      value={bulletpoint}
                      onChange={(e: any) =>
                        handleInputChange(e, index, "bulletpoint")
                      }
                    />
                    <span
                      className="cursor-pointer"
                      onClick={() => handleDeleteItem("bulletpoint", index)}
                    >
                      <TrashIcon />
                    </span>
                  </div>
                ))}
              </div>
              <div className="flex flex-col gap-4 border-[1px] border-gray p-4 rounded-md">
                <div className="flex justify-between items-center">
                  <div className="text-[14px] font-semibold">Tags</div>
                  <div
                    className="text-[14px] font-semibold cursor-pointer"
                    onClick={() => handleAddItem("tags")}
                  >
                    Add Tag +
                  </div>
                </div>

                {qoo.tags?.map((tag: any, index: number) => (
                  <div key={index} className="flex flex-row gap-2 items-center">
                    <Input
                      name={`tag-${index}`}
                      value={tag}
                      onChange={(e: any) => handleInputChange(e, index, "tags")}
                    />
                    <span
                      className="cursor-pointer"
                      onClick={() => handleDeleteItem("tags", index)}
                    >
                      <TrashIcon />
                    </span>
                  </div>
                ))}
              </div>

              <QooUploadDoc
                qooId={qoo?.id}
                triggerSave={isSaving}
                existingFiles={qoo?.documentFiles}
                onDeleteDocument={handleDeleteDocument}
              />

              <Separator />

              <VideoUploader
                qoo={qoo}
                setQoo={setQoo}
                handleClose={handleClose}
                onVideoGenerate={onVideoGenerate}
                generateTriggered={generateTriggered}
                setGenerateTriggered={setGenerateTriggered}
                isSaving={isSaving}
                isUploading={isSaving}
              />

              <Textarea
                name="anecdote"
                label="Anecodate"
                className="h-full p-5"
                rows={5}
                value={qoo?.anecdote}
                placeholder="Begin by clicking 'Generate Transcript' in the Toolkit"
                onChange={(e: any) =>
                  handleTextChange({
                    target: { name: "anecdote", value: e.target.value },
                  } as any)
                }
              />

              <Textarea
                name="transcriptText"
                label="Transcript Text"
                className="h-full p-5"
                rows={5}
                value={qoo?.transcriptText}
                onChange={(e: any) =>
                  handleTextChange({
                    target: { name: "transcriptText", value: e.target.value },
                  } as any)
                }
              />
            </div>
          </div>
        </DrawerBody>
        <DrawerStickyFooter>
          <div className="flex justify-end gap-2 w-full">
            <Button variant="outline" onClick={onClose} className="w-auto">
              Cancel
            </Button>
            <Button
              variant="default"
              onClick={handleSave}
              className="w-auto"
              disabled={generateTriggered}
            >
              Update Qoo
            </Button>
          </div>
        </DrawerStickyFooter>
      </DrawerContent>
    </Drawer>
  );
};

const VideoUploader = ({
  qoo,
  isSaving,
  isUploading,
  setQoo,
  handleClose,
  onVideoGenerate,
  generateTriggered,
  setGenerateTriggered,
}) => {
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [videoOpen, setVideoOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const videoGenerationInProgress = useMemo(() => {
    return (
      isGenerating || qoo.heygenVideo?.status === ProgressStatusEnum.STARTED
    );
  }, [isGenerating, qoo.heygenVideo?.status]);

  const handleGenerateVideo = async () => {
    toast({
      title: "Generating video",
      description:
        "This may take a few minutes. It is safe to close this drawer.",
    });

    setGenerateTriggered(true);
    await qooMediaService
      .generateHeygenVideo(qoo.id)
      .then(() => {
        setIsGenerating(true);
        setGenerateTriggered(false);
        if (onVideoGenerate) {
          onVideoGenerate(qoo);
        }

        toast({
          title: "Generating Video",
          description:
            "Video is being generated. It is safe to close this drawer.",
        });
      })
      .catch(() => {
        toast({
          title: "Error",
          description: "An error occurred while generating video",
        });

        setIsGenerating(false);
        setGenerateTriggered(false);
      });
  };

  const handleDeleteHeygenVideo = async () => {
    await qooMediaService
      .deleteHeygenVideo(
        qoo?.id,
        qoo?.heygenVideo?.id,
        qoo?.heygenVideo?.videoId
      )
      .then(() => {
        setQoo((prev) => ({
          ...prev,
          heygenVideo: null,
        }));

        toast({
          title: "Video Deleted",
          description: "Video has been deleted successfully",
        });

        handleClose();
      })
      .catch(() => {
        toast({
          title: "Error",
          description: "An error occurred while deleting video",
        });
      });
  };

  return (
    <div className="flex flex-col gap-4 border-[1px] border-gray p-4 rounded-md bg-gray-100">
      <div className="flex flex-col w-full gap-4">
        <div className="text-[16px] font-semibold">
          {qoo?.heygenVideo?.status === "COMPLETE"
            ? "AI Video Generated"
            : "Upload or Generate Video"}

          <div className="text-[13px] font-normal text-orange-500">
            If both an AI Video and uploaded video exist, the AI Video will be
            used.
          </div>
        </div>

        {!videoGenerationInProgress &&
          qoo?.heygenVideo?.status !== "COMPLETE" && (
            <>
              <QooUploadVideo
                qooId={qoo?.id}
                triggerSave={isSaving}
                existingVideo={qoo?.video}
              />

              {/* Horizontal like with word 'hello world' in the middle */}
              <Divider text="OR" />
            </>
          )}

        {/* automatically horizontal align middle */}
        <div className="flex items-center justify-center gap-4">
          {qoo?.heygenVideo?.status === "COMPLETE" ? (
            <>
              <div className="flex items-center justify-center gap-4">
                <Button
                  className="w-[200px]"
                  onClick={() => setVideoOpen(true)}
                  loading={videoGenerationInProgress}
                  disabled={videoGenerationInProgress}
                >
                  Watch AI Video
                </Button>
                <Button
                  variant="destructive"
                  onClick={() => setDeleteOpen(true)}
                  loading={videoGenerationInProgress}
                  disabled={videoGenerationInProgress}
                >
                  <Icons.Trash stroke="white" />
                </Button>
              </div>
            </>
          ) : (
            <Button
              className="w-[200px]"
              onClick={handleGenerateVideo}
              loading={videoGenerationInProgress || generateTriggered}
              disabled={videoGenerationInProgress || generateTriggered}
            >
              Generate Video
            </Button>
          )}
        </div>

        {videoGenerationInProgress && (
          <div className="flex flex-col items-center">
            <div className="text-[14px] text-orange-600 text-center">
              This may take a few minutes. It is safe to close this drawer.
            </div>

            <div className="flex items-center gap-2">
              <div className="text-[14px]">Time Elapsed:</div>
              <TimeElapsedTimer
                startDate={qoo?.heygenVideo?.createdDate || new Date()}
              />
            </div>
          </div>
        )}

        {!isGenerating &&
          !isUploading &&
          qoo.heygenVideo?.status === ProgressStatusEnum.ERROR && (
            <div className="text-[14px] text-red-600 text-center">
              There was an error generating the video. Please try again.
            </div>
          )}
      </div>

      <HtmlVideoModal
        isOpen={videoOpen}
        onClose={() => setVideoOpen(false)}
        src={qoo?.video?.presignedUrl || ""}
      />

      <AlertDialog
        header="Confirm Delete"
        body="Are you sure you want to delete this video?"
        variant="destructive"
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onConfirm={handleDeleteHeygenVideo}
      />
    </div>
  );
};
