import { Button } from "@/components/base";
import { Textarea } from "@/components/third-party/shadcn/textarea";
import { qooMediaService } from "@/service/qoo/qoo-media.service";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { HeygenTemplateDropdown } from "@/components/dropdown/HeygenTemplateDropdown";
import { Icons } from "@/components/icons";
import { toast } from "@/components/third-party/shadcn/use-toast";
import { AlertDialog } from "../alert/AlertDialog";

export const GenerateScriptModal = ({
  qooId,
  heygenThirdpartyId,
  isOpen,
  onClose,
  onGenerateVideo,
}: {
  qooId: number;
  heygenThirdpartyId: number;
  isOpen: boolean;
  onGenerateVideo: () => void;
  onClose: any;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [prompt, setPrompt] = useState(DEFAULT_PROMPT);
  const [initialScript, setInitialScript] = useState("");
  const [script, setScript] = useState("");
  const [templateId, setTemplateId] = useState(null);
  const [videoGenerating, setVideoGenerating] = useState(false);
  const [generateVideoOpen, setGenerateVideoOpen] = useState(false);
  const [closeOnConfirm, setCloseOnConfirm] = useState(false);

  const heygenWindow = useRef(null);

  const init = async () => {
    const script = await qooMediaService.getHeygenScript(heygenThirdpartyId);
    setInitialScript(script);
    setScript(script);
  };

  const handleGenerateScript = async () => {
    setIsLoading(true);
    const scriptResp = await qooMediaService.generateVideoScript(qooId, prompt);
    setScript(scriptResp);
    setIsLoading(false);
  };

  const handleGenerateVideo = async () => {
    setVideoGenerating(true);
    setIsLoading(true);
    await qooMediaService
      .regenerateHeygenVideo(qooId, templateId, script)
      .then(() => {
        setInitialScript(script);
        toast({
          title: "Video Generated",
          description:
            "Video is being generated. It is safe to close this drawer.",
        });

        onGenerateVideo();
        handleClose();
      })
      .catch(() => {
        toast({
          title: "Error",
          description: "An error occurred while generating video",
        });
      })
      .finally(() => {
        setVideoGenerating(false);
        setIsLoading(false);
      });
  };

  const handleSelectTemplate = (template) => {
    setTemplateId(template.template_id);
  };

  const handleClose = () => {
    if (heygenWindow.current) {
      heygenWindow.current.close();
      heygenWindow.current = null;
    }

    setPrompt(DEFAULT_PROMPT);
    setScript("");
    setTemplateId(null);
    setGenerateVideoOpen(false);
    setCloseOnConfirm(false);
    onClose();
  };

  const attemptClose = () => {
    if (script?.length || isLoading || generateVideoOpen) {
      setCloseOnConfirm(true);
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (heygenThirdpartyId && isOpen) {
      init();
    }
  }, [heygenThirdpartyId, isOpen]);

  // Automatically generate script on init
  useEffect(() => {
    if (qooId && isOpen) {
      handleGenerateScript();
    }
  }, [qooId, isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={attemptClose} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent py="1" px="5" minWidth="900px" minH="600px">
          <div className="mt-4 flex flex-row justify-between">
            <div>
              <div className="flex justify-between items-center font-semibold">
                Test Generate Script
              </div>
              <div className="text-sm text-[gray] align-center">
                {(script?.split(" ")?.length === 1
                  ? 0
                  : script?.split(" ")?.length) ?? 0}{" "}
                words · {script?.length ?? 0} characters
              </div>
            </div>
            <div onClick={attemptClose}>
              <Icons.Error />
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <div className=" w-[600px] border-r border-gray-300 pr-2">
              <Textarea
                disabled={isLoading}
                rows={18}
                className="!text-[black] p-4 mt-2"
                value={script}
                onChange={(e) => setScript(e.target.value)}
                placeholder="'Generate Script' or write one yourself!"
              />

              <HeygenTemplateDropdown onSelect={handleSelectTemplate} />

              <Button
                onClick={() => setGenerateVideoOpen(true)}
                disabled={isLoading || !script?.length}
                loading={videoGenerating}
                className="mt-2 mr-2"
              >
                Generate Video
              </Button>
            </div>

            <div className="w-[450px] pt-[6px]">
              <Textarea
                rows={16}
                title="Prompt"
                className="!text-[black] p-4"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
              <div className="text-sm text-[gray] my-2">
                Modify the prompt to generate a script
              </div>
              <div className="flex justify-end gap-2 mt-4">
                <Button
                  onClick={handleGenerateScript}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  <Icons.Wand stroke="white" />
                  Generate Script
                </Button>
              </div>
            </div>
          </div>
        </ModalContent>
      </Modal>
      <AlertDialog
        header="Confirm Generate"
        body="Are you sure you want to generate a video? Existing video will be overwritten."
        isOpen={generateVideoOpen}
        onClose={() => setGenerateVideoOpen(false)}
        onConfirm={handleGenerateVideo}
      />

      <AlertDialog
        header="Please confirm"
        variant="destructive"
        body="Are you sure you want to leave this page? Your changes will be lost."
        isOpen={closeOnConfirm}
        onClose={() => setCloseOnConfirm(false)}
        onConfirm={handleClose}
      />
    </>
  );
};

const DEFAULT_PROMPT = `
Using the provided original transcript and the corresponding learning point output, create a single 40- to 60-second talk track for this learning point. The talk track should:
          1.	Highlight the key action or concept outlined in the learning point.
          2.	Incorporate at least one example from the original transcript that supports or illustrates the action.
          3.	If there are multiple examples in the original transcript that relate to the learning point, include multiple examples to fully support the talk track.
          4.	Use details from the learning point (such as notes, supporting information, and anecdotes) to provide context and strengthen the message.
          5.	Ensure the talk track is clear and engaging, focusing on how the examples from the transcript directly reinforce the learning point.
          6.	Use examples from the original transcript to clearly illustrate the key message and provide real-life context.
        The goal is to build a concise, impactful message that aligns with the learning point, using real-life examples from the transcript to make the message relatable and memorable.
`;
